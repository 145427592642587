import logo_fortuna from '../assets/images/logo_header_color.jpg';
import logo_rawlings from '../assets/images/logo_header.svg';
import logo_deux from '../assets/images/logo_header_deux.jpeg';

const path_URL: string = window.location.hostname;

const checkPath = (path_URL: string) => {
  switch (path_URL) {
    // FORTUNA
    case 'colorsimulation.com':
      return {
        status: false,
        image_logo: logo_fortuna,
        title_header: '',
      }

    // STG FORTUNA
    case 'stg.colorsimulation.com':
      return {
        status: false,
        image_logo: logo_fortuna,
        title_header: '',
      }

    // RAWLINGS
    case 'rawlings.colorsimulation.com':
      return {
        status: true,
        image_logo: logo_rawlings,
        title_header: 'OFFICIAL GLOVE, BALL & HELMET OF MAJOR LEAGUE BASEBALL®',
      }

    // STG RAWLINGS
    case 'stg-rawlings.colorsimulation.com':
      return {
        status: true,
        image_logo: logo_rawlings,
        title_header: 'OFFICIAL GLOVE, BALL & HELMET OF MAJOR LEAGUE BASEBALL®',
      }

    // DEUX
    case 'deuxfoyer.colorsimulation.com':
      return {
        status: false,
        image_logo: logo_deux,
        title_header: '',
      }

    // STG DEUX
    case 'stg-deuxfoyer.colorsimulation.com':
      return {
        status: false,
        image_logo: logo_deux,
        title_header: '',
      }

    // ISOL
    case 'isol.colorsimulation.com':
      return {
        status: true,
        image_logo: logo_rawlings,
        title_header: 'OFFICIAL GLOVE, BALL & HELMET OF MAJOR LEAGUE BASEBALL®',
      }

    // STG ISOL
    case 'stg-isol.colorsimulation.com':
      return {
        status: true,
        image_logo: logo_rawlings,
        title_header: 'OFFICIAL GLOVE, BALL & HELMET OF MAJOR LEAGUE BASEBALL®',
      }

    // AKISHIMA
    case 'akishima-ryohin.colorsimulation.com':
      return {
        status: false,
        image_logo: logo_fortuna,
        title_header: '',
      }

    // COLOR_SIMULATION_DEMO
    case 'demo.colorsimulation.com':
      return {
        status: false,
        image_logo: logo_fortuna,
        title_header: '',
      }

    // LOCAL
    // case 'localhost':
    //   return {};

    default:
      /* Fortuna */
      return {
        status: false,
        image_logo: logo_fortuna,
        title_header: '',
      }

      /* Rawlings */
    //   return {
    //     status: true,
    //     image_logo: logo_rawlings,
    //     title_header: 'OFFICIAL GLOVE, BALL & HELMET OF MAJOR LEAGUE BASEBALL®',
    //   }
  }
};

const headerDynamic = checkPath(path_URL);

export default headerDynamic;
