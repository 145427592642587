import { Fragment, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Link, useLocation } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import HeaderCustomerPrint from '../../../components/HeaderCustomerPrint';
import icon_download from '../../../assets/images/icon-download.svg';
// import { orderApi } from '../../../api';
import formatJapan from '../../../helper/formatJapan';
import FooterCustomer from '../../../components/FooterCustomer';
import { Button, Modal, Spin } from 'antd';
import urlApi from '../../../helper/urlApi';

export const TempOrders = () => {
  const { state } = useLocation();
  // const [idPrint, setIdPrint] = useState<number>();
  // const { dataOrders, product_id, style_color_ids, text_style_colors } = state;
  const { dataOrders, priceProduct } = state;
  const componentRef = useRef<HTMLDivElement>(null);

  const [showModalDowload, setShowModalDowload] = useState(false);

  // Show Modal.
  const handleShowModalDowload = () => {
    setShowModalDowload(true);
  };
  // Cancel Modal.
  const handleCancelModalDowload = () => {
    setShowModalDowload(false);
  };

  // Handle Print
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: `order-rawlings-${idPrint}`,
    documentTitle: `order-rawlings-${dataOrders?.id}`,
  });

  const handleRowSpan = (data: any) => {
    let valueRowSpan = 1;
    if (data) {
      const rowSpanSubStyle = data?.sub_item_style_color.length;
      const rowSpanTextStyle = data?.text_style_color.length;
      if (rowSpanSubStyle === 0 && rowSpanTextStyle === 0) {
        valueRowSpan = 1;
      } else if (rowSpanSubStyle === 0 && rowSpanTextStyle !== 0) {
        valueRowSpan = rowSpanTextStyle + 1;
      } else if (rowSpanSubStyle !== 0 && rowSpanTextStyle === 0) {
        valueRowSpan = rowSpanSubStyle + 1;
      } else {
        valueRowSpan = rowSpanTextStyle + rowSpanSubStyle + 1;
      }
    }
    return valueRowSpan;
  };

  // Handle Save Print
  const handleExport = async () => {
    handlePrint();
    // const handleDataOrders = {
    //   product_id: product_id,
    //   product_tag_style_id: dataOrders.product_tag_styles.id,
    //   product_tag_play_id: dataOrders.product_tag_plays.id,
    //   product_tag_prefer_id: dataOrders.product_tag_prefers.id,
    //   product_tag_position_id: dataOrders.product_tag_positions.id,
    //   product_tag_size_id: dataOrders.product_tag_sizes.id,
    //   product_tag_throw_id: dataOrders.product_tag_throws.id,
    //   style_color_ids: style_color_ids,
    //   text_style_colors: text_style_colors,
    // };

    // try {
    //   const res: any = await orderApi.addOrders(handleDataOrders);

    //   if (res.status === 'success') {
    //     setIdPrint(res.data.id);
    //     setTimeout(() => {
    //       handlePrint();
    //     }, 100);
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const componentPrint = (
    <>
      <div ref={componentRef}>
        <HeaderCustomerPrint />
        <div className="main new_index">
          <div className="container" style={{ backgroundColor: 'white' }}>
            <h4 className="title-order">YOUR ORDER</h4>
            <div className="wrap-orders">
              <div className="border-image" style={{ border: '1px solid #cccccc', borderBottom: 0 }}>
                <img src={dataOrders?.image[0]?.image} alt="" />
              </div>
              <table className="table table2-6">
                <tbody>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="bdr h-30">ID Orders</td>
                    {/* <td className="bdr h-30">{idPrint}</td> */}
                    <td className="bdr h-30">{dataOrders?.id}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: "1px solid #cccccc" }}>
                    <td className="h-30 bdr bdl">モデル名</td>
                    <td className="h-30 bdr">{dataOrders?.product}</td>
                    <td className="bdr"></td>
                    <td className="h-30">{formatJapan.format(priceProduct || 0)}</td>
                  </tr>
                  {/* Order for Rawlings */}
                  {/* <tr style={{ border: "1px solid #cccccc" }}>
                    <td className="h-30 bdr bdl">プレイ</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_plays.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">ポジション</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_positions.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">モデル</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_prefers.name}</td>
                    <td className="h-30 bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">適応サイズ</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_sizes.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">スタイル</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_styles.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">利き腕</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_throws.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr> */}
                  {dataOrders?.item_style_color?.map((data: any) => (
                    <Fragment key={data.id}>
                      <tr key={data.id} style={{ border: '1px solid #cccccc' }}>
                        <td className="bdl h-30 bdr" rowSpan={handleRowSpan(data)}>
                          {data.item}
                        </td>
                        <td className="bdl h-30 bdr">{`スタイル：${data.style}`}</td>
                        <td className="bdl h-30 bdr">{`色：${data.color}`}</td>
                        <td className="bdl h-30">{formatJapan.format(data.style_price || 0)}</td>
                      </tr>
                      {data?.sub_item_style_color.length ? (
                        data?.sub_item_style_color.map((item: any) => (
                          <tr key={item.id} style={{ border: '1px solid #cccccc' }}>
                            <td className="bdl h-30 bdr">{`サブスタイル ：${item.style}`}</td>
                            <td className="bdl h-30 bdr">{`色：${item.color}`}</td>
                            <td className="bdl h-30">{formatJapan.format(item.style_price || 0)}</td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                      {data?.text_style_color.length ? (
                        data?.text_style_color.map((item: any) => (
                          <tr key={item.id} style={{ border: '1px solid #cccccc' }}>
                            <td className="bdl h-30 bdr">{`テキストスタイル ：${
                              item.text_value ? item.text_value : 'データはインプットの所をまだ入力しません'
                            }`}</td>
                            <td className="bdl h-30 bdr">{`色：${item.color}`}</td>
                            <td className="bdl h-30">{formatJapan.format(item.style_price || 0)}</td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </Fragment>
                  ))}
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">消費税（10%）</td>
                    <td className="h-30 bdr"></td>
                    <td className="bdr"></td>
                    <td className="h-30">{formatJapan.format(dataOrders?.price * 0.1)}</td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc', fontWeight: 500 }}>
                    <td className="h-30 bdr bdl">合計</td>
                    <td className="h-30 bdr"></td>
                    <td className="bdr"></td>
                    <td className="h-30">{formatJapan.format(dataOrders?.price + dataOrders?.price * 0.1)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="wrapper-page">
        <div className="main new_index">
          <div className="container" style={{ backgroundColor: 'white' }}>
            <h4 className="title-order">YOUR ORDER</h4>
            <div className="wrap-orders">
              <div className="border-image">
                <img src={dataOrders?.image[0]?.image} alt="" />
              </div>
              <table className="table table2-6">
                <tbody>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="bdr h-30">ID Orders</td>
                    <td className="bdr h-30">{dataOrders?.id}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: "1px solid #cccccc" }}>
                    <td className="h-30 bdr bdl">モデル名</td>
                    <td className="h-30 bdr">{dataOrders?.product}</td>
                    <td className="bdr"></td>
                    <td className="h-30">{formatJapan.format(priceProduct || 0)}</td>
                  </tr>
                  {/* Order for Rawlings */}
                  {/* <tr style={{ border: "1px solid #cccccc" }}>
                    <td className="h-30 bdr bdl">プレイ</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_plays.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">ポジション</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_positions.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">モデル</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_prefers.name}</td>
                    <td className="h-30 bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">適応サイズ</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_sizes.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">スタイル</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_styles.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">利き腕</td>
                    <td className="h-30 bdr">{dataOrders?.product_tag_throws.name}</td>
                    <td className="bdr"></td>
                    <td></td>
                  </tr> */}
                  {dataOrders?.item_style_color?.map((data: any) => (
                    <Fragment key={data.id}>
                      <tr key={data.id} style={{ border: '1px solid #cccccc' }}>
                        <td className="bdl h-30 bdr" rowSpan={handleRowSpan(data)}>
                          {data.item}
                        </td>
                        <td className="bdl h-30 bdr">{`スタイル：${data.style}`}</td>
                        <td className="bdl h-30 bdr">{`色：${data.color}`}</td>
                        <td className="bdl h-30">{formatJapan.format(data.style_price || 0)}</td>
                      </tr>
                      {data?.sub_item_style_color.length ? (
                        data?.sub_item_style_color.map((item: any) => (
                          <tr key={item.id} style={{ border: '1px solid #cccccc' }}>
                            <td className="bdl h-30 bdr">{`サブスタイル ：${item.style}`}</td>
                            <td className="bdl h-30 bdr">{`色：${item.color}`}</td>
                            <td className="bdl h-30">{formatJapan.format(item.style_price || 0)}</td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                      {data?.text_style_color.length ? (
                        data?.text_style_color.map((item: any) => (
                          <tr key={item.id} style={{ border: '1px solid #cccccc' }}>
                            <td className="bdl h-30 bdr">{`テキストスタイル ：${
                              item.text_value ? item.text_value : 'データはインプットの所をまだ入力しません'
                            }`}</td>
                            <td className="bdl h-30 bdr">{`色：${item.color}`}</td>
                            <td className="bdl h-30">{formatJapan.format(item.style_price || 0)}</td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </Fragment>
                  ))}
                  <tr style={{ border: '1px solid #cccccc' }}>
                    <td className="h-30 bdr bdl">消費税（10%）</td>
                    <td className="h-30 bdr"></td>
                    <td className="bdr"></td>
                    <td className="h-30">{formatJapan.format(dataOrders?.price * 0.1)}</td>
                  </tr>
                  <tr style={{ border: '1px solid #cccccc', fontWeight: 500 }}>
                    <td className="h-30 bdr bdl">合計</td>
                    <td className="h-30 bdr"></td>
                    <td className="bdr"></td>
                    <td className="h-30">{formatJapan.format(dataOrders?.price + dataOrders?.price * 0.1)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="pt-25 pb-25 block_button_main flex-center">
          <Link to={'/choose-colors'}>
            <button className="btn_main border btn_done icon-leftout " style={{ marginRight: 0 }}>
              <LeftOutlined />
              戻る
            </button>
          </Link>
          <button
            className="btn_main border btn_done icon-leftout"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 10,
              marginRight: 10,
            }}
            onClick={handleShowModalDowload}
          >
            画像ダウンロード
            <img src={icon_download} alt="" style={{ marginLeft: 5 }} />
          </button>
          <button className="btn_main btn_download border" style={{ position: 'relative' }} onClick={handleExport}>
            PDFファイル <img src={icon_download} alt="" />
          </button>
        </div>
      </div>

      {FooterCustomer()}

      {/* Component Print */}
      <div style={{ display: 'none' }}>{componentPrint}</div>

      {/* Modal Download Image */}
      <Modal
        title="Basic Modal"
        open={showModalDowload}
        centered
        onCancel={handleCancelModalDowload}
        footer={null}
        className="modal-dowload-image"
      >
        {dataOrders?.image[0]?.image ? (
          <img src={dataOrders?.image[0]?.image} alt="" />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin />
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          {dataOrders?.image[0]?.image ? (
            <Button
              type="primary"
              className="click-btn-save-image flex-box-center"
              href={`${urlApi}/customer/download-order-images?image_path=${dataOrders?.image[0]?.image}`}
            >
              ダウンロード
            </Button>
          ) : (
            ''
          )}
        </div>
      </Modal>
    </>
  );
};
