import config from "../config";
import AdminLayout from "../layouts/AdminLayout";
import CustomerLayout from "../layouts/CustomerLayout";
import {
  AddProduct,
  Dashboard,
  TagSeo,
  EditProduct,
  ListProduct,
  Login,
  // OtpConfirm,
  Products,
  Register,
  SelectedProduct,
  Setting,
  StyleColor,
  SubStyleColor,
  Home,
  TermUse1,
  TermUse2,
  ChooseOption,
  ChooseProduct,
  ChooseColors,
  // ChooseStyles,
  TempOrders,
  OverView,
  PreviewProduct,
  TextStyleColor,
} from "../pages";
import { RouteType } from "../types";

export const adminRoutes: any = [
  {
    path: config.routes.adminDasboard,
    component: Dashboard,
    layout: AdminLayout,
  },
  {
    path: config.routes.tagSeo,
    component: TagSeo,
    layout: AdminLayout,
  },
  { path: config.routes.products, component: Products, layout: AdminLayout },
  {
    path: config.routes.listProduct,
    component: ListProduct,
    layout: AdminLayout,
  },
  {
    path: config.routes.addProduct,
    component: AddProduct,
    layout: AdminLayout,
  },
  {
    path: config.routes.styleColor,
    component: StyleColor,
    layout: AdminLayout,
  },
  {
    path: config.routes.subStyleColor,
    component: SubStyleColor,
    layout: AdminLayout,
  },
  {
    path: config.routes.textStyleColor,
    component: TextStyleColor,
    layout: AdminLayout,
  },
  {
    path: config.routes.editProduct,
    component: EditProduct,
    layout: AdminLayout,
  },
  { path: config.routes.setting, component: Setting, layout: AdminLayout },
  {
    path: config.routes.selectedProduct,
    component: SelectedProduct,
    layout: AdminLayout,
  },
  {
    path: config.routes.previewProduct,
    component: PreviewProduct,
    layout: CustomerLayout,
  },
];

/* For Rawlings */
// export const customerRoutes: any = [
//   { path: config.routes.home, component: Home, layout: CustomerLayout },
//   { path: config.routes.home, component: ChooseProduct, layout: CustomerLayout },
//   { path: config.routes.termUse1, component: TermUse1, layout: CustomerLayout },
//   { path: config.routes.termUse2, component: TermUse2, layout: CustomerLayout },
//   { path: config.routes.chooseOption, component: ChooseOption, layout: CustomerLayout },
//   { path: config.routes.chooseProduct, component: ChooseProduct, layout: CustomerLayout },
//   // { path: config.routes.chooseStyles, component: ChooseStyles, layout: CustomerLayout },
//   { path: config.routes.chooseColors, component: ChooseColors, layout: CustomerLayout },
//   { path: config.routes.tempOrders, component: TempOrders, layout: CustomerLayout },
//   { path: config.routes.overView, component: OverView, layout: CustomerLayout },
// ];

/* For Fortuna */
export const customerRoutes: any = [
  {
    path: config.routes.home,
    component: ChooseProduct,
    layout: CustomerLayout,
  },
  {
    path: config.routes.chooseProduct,
    component: ChooseProduct,
    layout: CustomerLayout,
  },
  {
    path: config.routes.chooseColors,
    component: ChooseColors,
    layout: CustomerLayout,
  },
  {
    path: config.routes.tempOrders,
    component: TempOrders,
    layout: CustomerLayout,
  },
  { path: config.routes.overView, component: OverView, layout: CustomerLayout },
];

export const publicRoutes: RouteType[] = [
  { path: config.routes.login, component: Login },
  // { path: config.routes.otpConfirm, component: OtpConfirm },
  { path: config.routes.register, component: Register },
];
