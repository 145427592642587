const path_URL: string = window.location.hostname;

const checkPath = (path_URL: string) => {
  switch (path_URL) {
    // FORTUNA
    case "colorsimulation.com":
      return "https://api.colorsimulation.com/api";

    // STG FORTUNA
    case "stg.colorsimulation.com":
      return "https://stg.api.colorsimulation.com/api";

    // RAWLINGS
    case "rawlings.colorsimulation.com":
      return "https://api.rawlings.colorsimulation.com/api";

    // STG RAWLINGS
    case "stg-rawlings.colorsimulation.com":
      return "https://api.stg-rawlings.colorsimulation.com/api";

    // DEUX
    case "deuxfoyer.colorsimulation.com":
      return "https://api.deux.colorsimulation.com/api";

    // STG DEUX
    case "stg-deuxfoyer.colorsimulation.com":
      return "https://api.stg-deux.colorsimulation.com/api";

    // ISOL
    case "isol.colorsimulation.com":
      return "https://api.isol.colorsimulation.com/api";

    // STG ISOL
    case "stg-isol.colorsimulation.com":
      return "https://api.stg-isol.colorsimulation.com/api";

    // AKISIMA
    case "akishima-ryohin.colorsimulation.com":
      return "https://api.akishima-ryohin.colorsimulation.com/api";

    // COLOR_SIMULATION_DEMO
    case "demo.colorsimulation.com":
      return "https://api.demo.colorsimulation.com/api";

    // COLOR_SIMULATION_DEMO_2
    case "demo2.colorsimulation.com":
      return "https://api.demo2.colorsimulation.com/api";

    // STEAL
    case "stg-steal.colorsimulation.com":
      return "https://api.stg-steal.colorsimulation.com/api";

    // LOCAL
    case "localhost":
      // return 'http://127.0.0.1:8000/api';
      // return 'https://api.colorsimulation.com/api';
      return 'https://stg.api.colorsimulation.com/api';
      // return "https://api.stg-rawlings.colorsimulation.com/api";
      // return "http://100.108.33.73/api/";
      // return "https://api.demo.colorsimulation.com/api";
    // return "http://192.168.1.129:80/api";
  }
};

const urlApi = checkPath(path_URL);

export default urlApi;
